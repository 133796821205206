import styled from 'styled-components'

export const SeparatorWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  padding-top: 3.75rem;
`

export const Spacer = styled.div`
  padding-bottom: 6rem;
`
