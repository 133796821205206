import React from 'react'
import Container from '../../../layouts/Container/Container'
import { SeparatorWrapper, Spacer } from './Separator.style'

const Separator = () => {
  return (
    <Container>
      <SeparatorWrapper />
      <Spacer />
    </Container>
  )
}

export default Separator
