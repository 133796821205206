import React from 'react'

const QuoteIcon = ({ color }) => (
  <svg viewBox="0 0 64.71 51.9" width="70px" height="60px">
    <path
      d="M1.84,51.9,0,49q15.68-8.49,15.67-15.67a4.58,4.58,0,0,0-1.29-3.23,14.77,14.77,0,0,0-4.61-2.86q-5.16-2.85-7.28-5.67A11.53,11.53,0,0,1,.37,14.38,14,14,0,0,1,4.52,4.24,13.5,13.5,0,0,1,14.56,0,12.61,12.61,0,0,1,25,5.21q4,5.2,4,13.5,0,13.56-10.33,23.42a39.93,39.93,0,0,1-7,5.25A76,76,0,0,1,1.84,51.9Zm35.58,0-1.93-2.77q15.67-8.57,15.67-15.85A4.64,4.64,0,0,0,49.68,30a14,14,0,0,0-4.33-2.81A21.5,21.5,0,0,1,38,21.66a11.72,11.72,0,0,1-2.16-7.28A13.58,13.58,0,0,1,40.05,4.29a13.8,13.8,0,0,1,10-4.11,12.91,12.91,0,0,1,10.6,5.07q4.07,5.07,4.06,13.46,0,13.65-10.32,23.42A36.38,36.38,0,0,1,50,45.77a41.43,41.43,0,0,1-5.21,3C42.76,49.75,40.31,50.79,37.42,51.9Z"
      fill={color}
    />
  </svg>
)

export default QuoteIcon
