import React from 'react'

const ArrowLeft = (props, { className }) => (
  <svg viewBox="0 0 24.4 44.58" {...props} width="14px" height="24px" className={className}>
    <path
      d="M24,42a1.5,1.5,0,0,1-1.06,2.56,1.53,1.53,0,0,1-1.06-.44L1.05,23.36A.54.54,0,0,1,1,23.24l-1-.95L21.85.43A1.53,1.53,0,0,1,24,.43a1.52,1.52,0,0,1,0,2.13L4.24,22.3Z"
      fill="#06101a"
    />
  </svg>
)

export default ArrowLeft
